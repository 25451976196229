
.menu-icon-wrapper{
  width:30px;
  height:30px;
  display:flex;
  position: absolute;
  top: 20px;
  left: 20px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .menu-icon{
    width: 30px;
    height: 5px;
    background-color: white;
    position: relative;
    &:before {
      content: "";
      display: block;
      width: 30px;
      height: 5px;
      background-color: white;
      position: absolute;
      top: -10px;
      left: 0;
      transition: transform 0.2s ease-in 0.2s, top 0.2s linear;
    }
    &::after{
      content: "";
      display: block;
      width: 30px;
      height:5px;
      background-color: white;
      position: absolute;
      top: 10px;
      left:0;
      transition: transform 0.1s ease-in ,top 0.1s linear;
    }
    &.menu-icon-active{
      background-color: transparent;
    }
    &.menu-icon-active::before{
      transform: rotate(45deg);
      top:0;
      transition: top 0.2s linear,transform 0.2s ease-in 0.2s;
    }

    &.menu-icon-active::after{
      transform: rotate(-45deg);
      top: 0;
      transition: top 0.2s linear,transform 0.2s ease-in 0.2s;
    }
  }
}


