.form-container{
  .page-title{
    margin-bottom: 30px;
  }

  .spinner{
    margin: 40px auto 0 auto;
  }
  .row{
    display: flex;
    flex-direction: column;
    .frm-group{

      width: 100%;
    }
    @media( min-width: $bp-md)
    {
      flex-direction: row;
      gap: 30px;
      justify-content: space-between;
    }
  }

  .frm-group{
    width: 100%;
    margin-bottom: 20px;
    label{
      color: $color-primary;
      margin: 0 10px 5px 10px;
      display: inline-block;
    }
    .with-error{
      color: $danger;
      margin: 2px 10px 0 10px;
    }
  }
}

.input{
  outline: none;
  border: none;
  width: 100%;
  font-family: $font-regular;
  border-radius: 3px;
  background-color: $input-bg;
  border-bottom: solid 2px $input-border ;
  padding: 12px 10px;
  color: $input-color;
  transition: border-color 4ms ease-in-out;
  &:focus{
    border-color: $color-primary;
  }

}
textarea{
  &.input{
    min-height: 90px;
  }
}
.preview-container{
  padding: 50% 0;
  box-sizing: border-box;
  border: dashed 3px $color-primary;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:before{
    content: url("img/plus.svg");
    display: inline-block;
    width: 30px;
    position: absolute;
    z-index: 9;
  }
  .image{
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .with-error{
    top: 0;
    left: 0;
    padding: 0 10px;
    color: $danger;
    position: absolute;
  }
}

.btn-flat{
  padding: 20px 40px;
  border: none;
  background-color: $color-primary;
  color: $white;
  font-size: 2rem;
  cursor: pointer;
  &:disabled{
    background-color: #dfdfdf;
    color: #888888;
    cursor: not-allowed;
  }
}

.center-inline{
  text-align: center;
}
.mb-40{
 margin-bottom: 40px;
}



.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  width: 50px;
  height: 50px;
  display: block;
  & .path {
    stroke: $color-primary;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
.flex-direction-column{
  flex-direction: column;
}
