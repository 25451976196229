@import "burger";
.menu-wrapper{
  background-color: $white;
  display: flex;
  justify-content: center;
  z-index: 9;
  border-radius: 10px;
  position: fixed;
  margin-top: 0;
  left: 50%;
  transform: translate(-50%, -10%);
  width: 80%;
  padding: 30px 20px;
  visibility: hidden;
  opacity: 0;
  transition-property: visibility, opacity, transform;
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;

  &.visible{
    visibility: visible;
    opacity: 1;
    transform: translate(-50%, 0);
  }
  @media( min-width: 325px )
  {
    width: 300px;
    padding: 30px 20px;
  }
  .menu-primary{
    li{
      margin-bottom: 1.6rem;
      &:last-child{
        margin: 0;
      }
      a{
        font-family: $font-semiBold;
        color: $black;
        font-size: 1.1rem;
        transition: color 300ms ease-in-out;
        &:hover{
          color: $color-primary;
        }
      }
    }
  }
}

