
@font-face {
  font-family: 'MontserratRegular';
  src: url('../fonts/Montserrat-Regular.eot');
  src: url('../fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Montserrat-Regular.woff2') format('woff2'),
  url('../fonts/Montserrat-Regular.woff') format('woff'),
  url('../fonts/Montserrat-Regular.ttf') format('truetype'),
  url('../fonts/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MontserratSemiBold';
  src: url('../fonts/Montserrat-SemiBold.eot');
  src: url('../fonts/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Montserrat-SemiBold.woff2') format('woff2'),
  url('../fonts/Montserrat-SemiBold.woff') format('woff'),
  url('../fonts/Montserrat-SemiBold.ttf') format('truetype'),
  url('../fonts/Montserrat-SemiBold.svg#Montserrat-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}


