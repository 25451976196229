$color-primary : #E20079;
$white : #FFFFFF;
$black : #000000;
$gray-light : #F2F2F2;
$danger : #b01515;

$input-bg: #e6e6e6;
$input-border: #c3c3c3;
$input-color: #515151;

$font-regular: 'MontserratRegular';
$font-semiBold: 'MontserratSemiBold';
$font-size-base: 14px;

$bp-sm: 576px;
$bp-md: 768px;
$bp-ld: 992px;
$bp-xld: 1200px;
$bp-xxld: 1400px;