body{
  font-family: $font-regular;
  font-size: $font-size-base;
  background-color: $color-primary;
  line-height: 1.6rem;
  a{
      text-decoration: none;
      color: initial;
  }
}

.page-container{
  width: 100%;
  padding: 0 20px;

  @media( min-width: $bp-xld )
  {
    position: relative;
    width: 1200px;
   margin-left:auto ;
    margin-right: auto;
  }
}
.progress-bar{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  background: $white;
  transform-origin: 0%;
  z-index: 10;
}
.app{
  padding-top: 70px ;
  position: relative;
  width: 100%;
}

.tiny{
  h2,
  h3,
  h4,
  h5,
  h6{
    font-family: $font-semiBold;
  }
  text-align: justify;
  font-size: 1.2rem;
  p{
    margin-bottom: 1.4rem;
  }
  strong{
    font-family: $font-semiBold;
    font-size: 1.6rem;
    margin-bottom: 1.4rem;
  }
  ul, ol{
    margin-bottom: 1.4rem;
    li{
      margin-bottom: 1.4rem;
    }
  }
}

.boxed{
  background-color: $gray-light;
  padding: 20px;
  position: relative;
  margin-bottom: 160px;
  @media( min-width: $bp-sm)
  {
    padding: 20px 70px;
  }
  &:after,  &:before{
    content: url("img/bg.svg");
    display: inline-block;
    width: 100%;
    height: 100px;
    overflow: hidden;
    position: absolute;
    left: 0;
    right: 0;
  }
  &:after{
    top: -77px;

  }
  &:before{
    bottom: -77px;
    transform: scale(-1);
  }
}

.page-title{
  color: $black;
  text-align: center;
  margin-bottom: 60px;
  font-size: 1.4rem;
  @media( min-width: $bp-sm)
  {
    font-size: 2rem;
  }
}

.lang{
  width: 100%;
  margin-bottom: 20px;
  text-align: center;
}
.lang-switcher{
  display: inline-block;
  li{
    display: inline-block;
    margin: 0 5px;

    button{
      cursor: pointer;
      min-width: 90px;
      font-family: $font-semiBold;
      font-size: 1rem;
      display: inline-block;
      padding: 10px;
      background-color: transparent;
      border: none;
      &.active{
        color: $color-primary;
      }
    }
  }
}